import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Link, Section, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdMailOutline } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Benvenuti in AutoPrime
			</title>
			<meta name={"description"} content={"Cura dell'auto eccezionale, ogni volta"} />
			<meta property={"og:title"} content={"Benvenuti in AutoPrime"} />
			<meta property={"og:description"} content={"Cura dell'auto eccezionale, ogni volta"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665dc0771d712a0023346b1a/images/2-4.jpg?v=2024-06-10T08:58:48.096Z"} />
		</Helmet>
		<Components.Header />
		<Components.Marque />
		<Section padding="80px 0 80px 0" background="--color-dark" color="--lightD2" quarkly-title="Contacts-8">
			<Override slot="SectionContent" flex-direction="row" align-items="flex-start" lg-flex-direction="column" />
			<Box
				min-width="100px"
				min-height="100px"
				width="40%"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				justify-content="center"
				lg-margin="0px 0 30px 0px"
				align-items="flex-start"
				sm-margin="0px 0px 0 0px"
				lg-padding="0px 180px 0px 0px"
				sm-padding="0px 0 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="--light">
					Prova lo standard AutoPrime
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
					color="--lightD2"
					lg-margin="0px 0px 20px 0px"
					md-width="100%"
				>
					Prenota il tuo servizio oggi e lascia che ti mostriamo perché AutoPrime è leader nella cura dell'auto. Il nostro impegno per la qualità e la soddisfazione del cliente garantisce ogni volta un'esperienza di servizio superiore. Noi di AutoPrime non ci limitiamo a riparare le auto, le perfezioniamo.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0 0 10%"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="36px 34px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				width="50%"
				lg-margin="0px 0 0 0"
				lg-width="100%"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdLocationOn}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:+9877654321223"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							Via Errico Malatesta, 9,{" "}
							<br />
							00149 Roma RM, Italy
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="+393930307904"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							+393930307904
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
				>
					<Icon
						category="md"
						icon={MdMailOutline}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="info@autoprime.com"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							info@autoprime.com
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							FAQ - Domande Frequenti
						</Text>
						<Text as="p" font="--base" margin="0" color="--dark">
							<Em>
								Presso AutoPrime, ci impegniamo a fornire il miglior servizio possibile. Di seguito troverai le risposte ad alcune delle domande più comuni che riceviamo dai nostri clienti.
							</Em>
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Quali servizi offrite presso AutoPrime?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Offriamo una gamma completa di servizi per la cura dell'auto, inclusi manutenzione ordinaria, riparazioni meccaniche, diagnostica elettronica, servizi di carrozzeria, cambio olio, controllo e sostituzione pneumatici, e molto altro.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Come posso prenotare un appuntamento?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Puoi prenotare un appuntamento chiamandoci al numero [*Inserisci numero di telefono*], inviandoci un'email a [*Inserire indirizzo e-mail*], o utilizzando il nostro sistema di prenotazione online sul sito web.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Quanto tempo ci vuole per un cambio olio?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Il cambio olio di solito richiede circa 30-45 minuti. Tuttavia, il tempo può variare a seconda del tipo di veicolo e delle condizioni specifiche.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Offrite servizi di riparazione d'urgenza?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Sì, offriamo servizi di riparazione d'urgenza per garantire che il tuo veicolo torni in strada il più rapidamente possibile. Contattaci immediatamente se hai bisogno di assistenza urgente.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Forniamo un'auto sostitutiva mentre la mia è in riparazione?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Sì, offriamo auto sostitutive per i clienti il cui veicolo richiede riparazioni estese. Verifica la disponibilità con il nostro team al momento della prenotazione.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Quali tipi di pagamento accettate?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Accettiamo contanti, carte di credito/debito e bonifici bancari. Puoi discutere le opzioni di pagamento con il nostro team al momento del servizio.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Come posso ottenere un preventivo per una riparazione?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Per ottenere un preventivo, puoi portarci il tuo veicolo per una diagnosi, o contattarci con una descrizione dettagliata del problema. Ti forniremo un preventivo dettagliato basato sulle necessità specifiche della tua auto.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Utilizzate parti di ricambio originali?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Sì, utilizziamo parti di ricambio originali per garantire la massima qualità e compatibilità con il tuo veicolo. Se preferisci, possiamo anche discutere l'uso di parti aftermarket di alta qualità.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Quanto spesso dovrei far controllare la mia auto?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Raccomandiamo di far controllare la tua auto almeno una volta all'anno o ogni 10.000 chilometri, a seconda di quale delle due condizioni si verifichi prima. Questo aiuta a mantenere il veicolo in condizioni ottimali e a prevenire problemi futuri.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Offrite garanzie sui vostri servizi?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--dark">
									Sì, offriamo garanzie sui nostri servizi per assicurarti la massima tranquillità. La durata e le condizioni della garanzia variano a seconda del tipo di servizio eseguito. Contattaci per maggiori dettagli sulle garanzie specifiche.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text3">
				Via Errico Malatesta, 9,{" "}
				<br />
				00149 Roma RM, Italy
			</Override>
			<Override slot="link3">
				+393930307904
			</Override>
			<Override slot="link4">
				info@autoprime.com
			</Override>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});